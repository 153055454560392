import Layout from "components/layout";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
import React, { useEffect, useRef, useState } from "react";
import TranscriptionTool from "components/TranscriptionTool/TranscriptionTool";
import Uploadfiles from "components/TranscriptionTool/Uploadfiles";
import ToolFaq from "components/QualityTools/ToolFaq";
import { TranscriptionToolFaqData } from "components/FAQ/config";
import http from "utils/http";
import { endpoints } from "utils/endpoints";
import Loader from "components/loader";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";
import SEO from "components/seo";
import ComparisonTable from "components/TranscriptionTool/ComparisonTable";

const TranscriptionResult = () => {
  const [files, setFiles] = useState([]);
  const [outputText, setOutputText] = useState("");
  const [gptText, setGptText] = useState("");
  const [geminiText, setGeminiText] = useState("");
  const [amazonText,setAmazonText] = useState("")
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState(false);
  const [inputLang, setInputLang] = useState("");
  const [comparedResults, setComparedResults] = useState([]);
  const [geminiError ,setGeminiError] = useState("")
  const [amazonError,setAmazonError] = useState("")
  const [result,setResult ] = useState("")
  const [metaText,setMetaText] = useState("")
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const displayShareId = params.get("share-id");

  useEffect(() => {
    if (!displayShareId) return;
    let intervalId = null;
    const fetchAnalysis = async () => {
      try {
        const response = await http().get(
          `${endpoints.forms.aiTools}/${displayShareId}`
        );
     

       setResult(response);
      // console.log(num,"num")
        setFiles(response);
        setInputLang(response?.meta?.language_code);
        setOutputText(response?.result?.result);
        setGeminiText(
         ( response?.result?.result?.gemini_result?.transcription?.transcription || response?.result?.result?.gemini_result?.transcription?.message ) || response?.result?.result?.gemini_result?.error_message
        );
        setGeminiError(response?.result?.result?.gemini_result?.error_message)
        setAmazonText(response?.result?.result?.amazon_transcribe_result?.transcription || response?.result?.result?.amazon_transcribe_result?.error_message)
       setAmazonError(response?.result?.result?.amazon_transcribe_result?.error_message)
        if (response?.result?.compared_result) {
          const parsedResult = JSON.parse(response?.result?.compared_result);
          setComparedResults(parsedResult?.differences);
        }

        setLoader(false);
      } catch (err) {
        setError(err.message || "An unexpected error occurred.");
        setLoader(false);
      }
    };
    fetchAnalysis();
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [displayShareId]);

  // useEffect(() => {
  //   if (comparedResults)
  // }, [comparedResults]);

  if (loader)
    return (
      <div className="grid place-content-center h-[calc(100vh-150px)]">
        <Loader />
      </div>
    );

  return (
    <Layout>
      <SEO
        title="Free AI Transcription Tool - Transcribe audio to text "
        description="Transcribe audio to text with the Tomedes AI Transcription Tool. Get fast, accurate, and free AI transcription with no sign-up required. Supports file formats including MP3, WAV, MP4, and more."
        slug="/tools/ai-transcription"
      />
      <div>
        <div className="w-full py-7 ">
          <ToolsBreadcrumb />
          <div className="max-w-7xl mx-auto px-4 lg:pt-[60px] pt-8  ">
            <h1 className="text-[#5B93FF] font-primary text-base uppercase text-center font-semibold mb-1.5">
            Free AI Transcription Tool for Audio-to-Text Conversion
            </h1>
           
            <div className="mt-4 flex lg:flex-row flex-col gap-4 p-4">
              {/* {!geminiError && (
              <ComparisonTable results={comparedResults ?? []} />
            )} */}
              <TranscriptionTool
                outputText={outputText}
                loader={loader}
                error={error}
                inputLang={inputLang}
                displayShareId={displayShareId}
                gptText={gptText}
                geminiText={geminiText}
                setGeminiText={setGeminiText}
                files={files}
                setFiles={setFiles}
                setComparedResults={setComparedResults}
                amazonText={amazonText}
                setAmazonText={setAmazonText}
                amazonError={amazonError}
                setAmazonError={setAmazonError}
                metaText={metaText}
                setMetaText={setMetaText}
                result={result}
                setResult={setResult}
                comparedResults={comparedResults}
              />
               
            </div>
          </div>
          <p className="w-full text-center text-[12px] text-[#6E6E6E] font-opensans mb-4 ">
            Assisted by AI tools. Inaccuracies may occur.
          </p>
          <p className="mt-8 max-w-5xl mx-auto md:mt-12 text-lg text-center font-opensans lg:px-0 px-4">
            This AI Transcription Tool makes it super easy to turn audio and
            video into text. Just upload your file—mp3, mp4, wav, and more—and
            get an instant, accurate transcription in over 50 supported
            languages. No matter if you're working with interviews, meetings, or
            lectures, this tool makes your workflow easier with fast, reliable
            results.
          </p>
        </div>
      </div>
      <ToolFaq QaToolFaqData={TranscriptionToolFaqData} />
    </Layout>
  );
};

export default TranscriptionResult;
